body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .prof-pic{
  padding:1px;
  border:1px solid #021a40;
} */

/* .expand{
  display: none;
} */

.lin:link {
  color: black;
}


.lin:visited {
  color: gray;
}
.lin:hover {
  color: purple;
}

@media only screen and (min-width: 768px) {

  .expand {
    display: none;
  }
  #prof-pic{
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .collapse {
    display: none;
  }
  #prof-pic{
    width: 80%;
  }
  .expand {
    display: show;
  }
}

.Navbar .Navbar.Toggle .Navbar.Collapse .NavDropDown {
  border-width: 0px;
  -webkit-box-shadow: 0px 0px;
  box-shadow: 0px 0px;
  background-color: rgba(0, 0, 0, 0);
  background-image: -webkit-gradient(
    linear,
    50% 0%,
    50% 100%,
    color-stop(0%, rgba(0, 0, 0, 0)),
    color-stop(100%, rgba(0, 0, 0, 0))
  );
  background-image: -webkit-linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.navBar {
  color: #ffffff;
}